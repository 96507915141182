<template>
  <v-main>
    <v-container>
      <h3 class="mb-5 mt-5">Create Notification</h3>
      <section v-if="errored">
        <p>
          We're sorry, we're not able to retrieve this information at the
          moment, please try back later
        </p>
      </section>
      <v-btn @click="goBackToNotificationList">Back to Notification List</v-btn>
      <div v-if="loading">Loading...</div>
      <v-form>
        <v-text-field
          v-model="notification.title"
          placeholder="Subject"
          required
        >
          <template v-slot:label>
            <div>Notification Title or Subject</div>
          </template>
        </v-text-field>
        <v-textarea
          v-model="notification.message"
          required
          label="Message"
          counter="150"
        ></v-textarea>
        <!-- <p>Scheduled At</p>
        <v-container>
          <v-row>
        <v-date-picker v-model="scheduleDate" landscape>
          <template v-slot:label>
            <div>Schedule Date</div>
          </template>
        </v-date-picker>
        <v-time-picker v-model="scheduleTime" landscape>
          <template v-slot:label>
            <div>Schedule Time</div>
          </template>
        </v-time-picker>
      </v-row>
        </v-container> -->
        <v-select
          :items="userTypeList"
          item-text="name"
          item-value="value"
          label="Target"
          v-model="notification.userType"
          required
          multiple
        >
          <template v-slot:label>
            <div>Target</div>
          </template>
        </v-select>

        <fileInput
          v-model="notification.coverImageUrl"
          label="Upload Image"
          @get-url="setFeatureImageUrl($event)"
          :imageUrl="notification.coverImageUrl"
        />

        <v-select
          :items="postListData"
          item-text="title"
          item-value="slug"
          label="Target"
          v-model="notification.slug"
        >
          <template v-slot:label>
            <div>Post Slug</div>
          </template>
        </v-select>

        <div class="mb-5">
          <v-btn
            :disabled="loading"
            :loading="loading"
            @click="createNotification"
            color="indigo-darken-3"
            >Create</v-btn
          >
        </div>
      </v-form>

      <v-btn @click="resetNotification">Reset</v-btn>
    </v-container>
  </v-main>
</template>

<script>
// @ is an alias to /src
// import { mapGetters } from "vuex";
import {
  CREATE_NOTIFICATION,
  FETCH_POST_SLUG_LIST,
} from "../store/actions.type";

import fileInput from "@/components/FileUploadComponent.vue";

export default {
  name: "CreateNotification",
  components:{
    fileInput
  },
  data() {
    return {
      postListData: [],
      scheduleDate: "",
      scheduleTime: "",
      notification: {
        title: null,
        message: null,
        userType: [],
        coverImageUrl: "",
        schedule_at: "",
        clickAction: "post",
        slug: "",
        pageName: "post",
      },
      loading: false,
      errored: false,
      userTypeList: [
        { name: "Non Provider", value: "non_provider" },
        { name: "Provider", value: "provider" },
        { name: "Developer", value: "developer" },
      ],
    };
  },
  mounted() {},
  created() {
    this.getPostSlugList();
  },
  methods: {
    setFeatureImageUrl(event) {
      this.notification.coverImageUrl = event;
    },
    getPostSlugList() {
      this.postListData = [{
        title: "Select",
        slug: ""
      }];
      this.$store
        .dispatch(FETCH_POST_SLUG_LIST)
        .then((data) => {
          this.loading = false;
          this.postListData = data;
          console.log(this.postListData);
        })
        .catch((response) => {
          console.log(response);
          this.loading = false;
          this.errored = true;
        })
        .finally(() => {
          this.loading = false;
          this.errored = false;
        });
    },
    onChildUpdate(newValue) {
      this.value = newValue;
      console.log(this.value);
    },
    goBackToNotificationList() {
      this.$router.push({
        name: "Notifications",
      });
    },
    resetNotification() {
      this.notification = {
        title: "",
        message: "",
        userType: "all",
        imageUrl: "",
        schedule_at: "",
        slug: "",
      };
    },
    createNotification() {
      console.log(this.notification);

      if (
        this.notification.title != "" &&
        this.notification.message != "" &&
        this.notification.userType != ""
      ) {
        this.$swal
          .fire({
            title: "Please confirm to send out push notification",
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: "Yes",
            denyButtonText: `No`,
          })
          .then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
              this.$store
                .dispatch(CREATE_NOTIFICATION, {
                  notificationData: this.notification,
                })
                .then(() => {
                  this.$swal.fire(
                    "Notification Sent Successfully",
                    "",
                    "success"
                  );
                  this.$router.push({
                    name: "Notifications",
                  });
                  this.loading = false;
                })
                .catch((response) => {
                  this.$swal.fire("Failed to send notification!", "", "danger");
                  console.log(response);
                  this.loading = false;
                  this.errored = true;
                })
                .finally(() => (this.loading = false));
            }
          });
      }
    },
  },
};
</script>
