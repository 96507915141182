<template>
  <div>
    <v-file-input
      prepend-icon="mdi-camera"
      ref="fileUpload"
      :id="fileInputId"
      clearable
      type="file"
      :rules="rules"
      accept="image/png, image/jpeg, image/jpg"
      :label="label"
      show-size
      variant="outlined"
      @click:clear="clearImage"
      @change="onFileChange"
    ></v-file-input>
    <p>
      Image Preview &nbsp;
      <v-btn @click="uploadFile" small color="green accent-1">Upload</v-btn>
    </p>

    <v-img
      :contain="true"
      :src="imageUrl"
      style="
        border: 3px solid #3333;
        object-fit: cover;
        width: 50%;
        height: 50%;
      "
    />
  </div>
</template>
    
    <script>
import { UPLOAD_MEDIA } from "../store/actions.type";
export default {
  name: "FileUploadComponent",
  props: {
    label: {
      type: String,
      default: "",
    },
    imageUrl: {
      type: String,
      default: "https://media.milady-rh.com/upload/files/milady.png",
    },
    fileInputId: {
      type: String,
      default: "fileInput1",
    },
    outlined: {
      type: String,
      default: "outlined",
    },
  },
  data() {
    return {
      defaultImageUrl:"https://media.milady-rh.com/upload/files/milady.png",
      image: undefined,
      // to save image url
      rules: [
        (value) => {
          return (
            !value ||
            !value.length ||
            value[0].size < 2000000 ||
            "File size should be less than 2 MB!"
          );
        },
      ],
    };
  },
  methods: {
    uploadFile() {
      if (this.image != undefined) {
        this.$store
          .dispatch(UPLOAD_MEDIA, this.image)
          .then((data) => {
            this.loading = false;
            this.imageUrl = data.url;
            this.setImageUrl(this.imageUrl);
            this.$swal.fire("Image uploaded successfully!", "", "success");
          })
          .catch((response) => {
            this.$swal.fire("Failed to upload image!", "", "danger");
            console.log(response);
            this.loading = false;
            this.errored = true;
          })
          .finally(() => (this.loading = false));
      }
    },
    clearImage() {
      this.imageUrl = this.defaultImageUrl;
    },
    setImageUrl(event) {
      this.$emit("get-url", event);
    },
    createImage(file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        this.imageUrl = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    onFileChange(file) {
      if (!file) {
        return;
      }
      this.image = file;
      this.createImage(file);
    },
  },
};
</script>
    